angular.module("vgresiduos").controller("ChecklistGridCtrl", [
	"$scope",
	"$state",
	"auditService",
	"$rootScope",
	"$q",
	"accountService",
	"dialogService",
	"actionPlanService",
	"httpService",
	"reportService",
	function (
		$scope,
		$state,
		auditService,
		$rootScope,
		$q,
		accountService,
		dialogService,
		actionPlanService,
		httpService,
		reportService
	) {
		"use strict";

		// Local variables
		const supplierId = $scope.transporterId;
		const currentClient = accountService.getCurrentClient();

		// Scope variables
		$scope.loadingAudits = false;
		$scope.filters = { onlyInternal: false, onlyActive: true };
		$scope.goActionPlanEdit = goActionPlanEdit;

		this.$onInit = function () {
			$scope.loadingAudits = true;

			loadGridOptions();
			loadAudits();
		};

		function loadGridOptions() {
			const columns = getGridColumns();

			$scope.gridOptions = {
				hasPermission: function () {
					return true;
				},
				columnDefinitions: columns,
				title: $rootScope.labels.CHECKLIST,
				query: {
					limit: 100,
					page: 1
				},
				data: [],

				rowClick: function (item) {
					goToAuditEditPage(item);
				},
				edit: function (item) {
					goToAuditEditPage(item);
				},
				create: function () {
					const dialog = dialogService
						.showDialogFromTemplateWithScope(
							null,
							"views/audit/popups/createAuditPopup_template.html",
							"CreateAuditPopupCtrl",
							$scope,
							"#audit-popup",
							false,
							function () {}
						)
						.then(
							function () {},
							function () {}
						);
					$scope.dialog = dialog;
				},

				promise: {},
				hasData: false,
				errorLoading: false,
				removeDelete: true,
				removeSearch: true,
				insertButton: {
					displayName: $scope.labels.TO_AUDIT
				},
				removeNew: !$scope.ctrl.isAuditor
			};

			$rootScope.contentNavBarGrid = $scope.gridOptions;
		}

		function getGridColumns() {
			const columns = [
				{
					field: "Responsible.Name",
					displayName: function () {
						return $rootScope.labels.RESPONSIBLE;
					}
				},
				{
					field: "AuditDateString",
					displayName: function () {
						return $rootScope.labels.AUDIT_DATE;
					}
				},
				{
					field: "ClassificationDescription",
					displayName: function () {
						return $rootScope.labels.CLASSIFICATION;
					},
					iconClass: "align-icon",
					classes: "m-l-30",
					orderField: "Classification",
					icon: {
						iconImageClass: "icon-resize",
						property: "ClassificationImg",
						condition: function (row) {
							if (row.Classification) {
								return true;
							}
							return false;
						}
					}
				},
				{
					field: "StatusDescription",
					displayName: function () {
						return $rootScope.labels.STATUS;
					}
				},
				{
					field: "LevelDescription",
					displayName: function () {
						return $rootScope.labels.LEVEL;
					}
				},
				{
					field: "ExpirationDateString",
					displayName: function () {
						return $rootScope.labels.EXPIRATION;
					}
				}
			];

			if ($scope.ctrl.isAuditor) {
				columns.push({
					editOptions: listRowActions(),
					displayName: function () {
						return $rootScope.labels.ACTIONS;
					}
				});
			}

			if (!$scope.ctrl.isAuditor) {
				columns.splice(0, 0, {
					field: "ClientName",
					displayName: function () {
						return $rootScope.labels.CLIENT;
					}
				});
			}

			return columns;
		}

		function listRowActions() {
			const actions = [
				{
					type: "custom",
					icon: "print",
					label: $scope.labels.PRINT,
					disable: function (row) {
						return row.Status == Vgr.enumerations.audit.Status.Canceled;
					},
					click: function (row) {
						const dto = {
							auditId: row.Id,
							filters: {
								Extension: Vgr.enumerations.reportExtension.Pdf
							}
						};
						return httpService.getDTOFromServiceV2(reportService.generateClientAuditDocument, dto).then(
							function (data) {
								const fileDTO = Vgr.util.fileDtoToFile(data.File);
								saveAs(fileDTO, fileDTO.FileName);
							},
							function () {}
						);
					}
				},
				{
					type: "custom",
					icon: "edit",
					label: $scope.labels.EDIT,
					click: goToAuditEditPage,
					disable: function () {
						return false;
					}
				},
				{
					type: "custom",
					icon: "note_add",
					label: $rootScope.labels.CREATE_ACTION_PLAN,
					disable: function (row) {
						return !row.CanCreateActionPlan;
					},
					click: function (row) {
						return createActionPlan(row.Id).then(function (actionPlanId) {
							goActionPlanEdit(actionPlanId);
						});
					}
				},
				{
					type: "custom",
					icon: "cancel",
					label: $scope.labels.CANCEL,
					disable: function (row) {
						return !isOwner(row) || canDeleteAudit(row) || row.Status == Vgr.enumerations.audit.Status.Canceled;
					},
					click: function (row) {
						$rootScope.$broadcast(Vgr.constants.evtConfirmOperation, {
							text: $rootScope.labels.CANCEL_AUDIT_CONFIRMATION,
							id: row.Id
						});
					}
				},
				{
					type: "custom",
					icon: "delete",
					label: $scope.labels.DELETE,
					disable: function (row) {
						return !isOwner(row) || !canDeleteAudit(row);
					},
					click: function (row) {
						$rootScope.$broadcast(Vgr.constants.evtConfirmOperation, {
							text: $rootScope.labels.DELETE_AUDIT_CONFIRMATION,
							id: row.Id
						});
					}
				}
			];

			return actions;
		}

		function canDeleteAudit(audit) {
			return (
				audit.Status == Vgr.enumerations.audit.Status.Pending ||
				audit.Status == Vgr.enumerations.audit.Status.WaitingSupplier
			);
		}

		function isOwner(audit) {
			return auditService.isOwner(audit, currentClient);
		}

		function goToAuditEditPage(audit) {
			$state.go("auditEdit", { supplierId: currentClient.id, auditId: audit.Id });
		}

		function loadAudits() {
			$scope.loadingAudits = true;

			let service;
			const dto = {};

			if ($scope.ctrl.isAuditor) {
				service = auditService.listSupplierAudits;

				dto.auditedClientId = currentClient.id;
				dto.onlyActive = $scope.filters.onlyActive;
			} else {
				service = auditService.listAuditsAsAudited;

				dto.auditedByOthers = true;
				dto.onlyActive = true;
			}

			const deferred = $q.defer();
			httpService.getListFromServiceV2(service, dto, "Audits").then(
				function (list) {
					fillAuditList(list);
					$scope.gridOptions.data = list;
					$scope.loadingAudits = false;
					deferred.resolve();
				},
				function () {
					$scope.loadingAudits = false;
					deferred.reject();
				}
			);

			$scope.gridOptions.promise = deferred.promise;
		}

		function fillAuditList(auditList) {
			for (const audit of auditList) {
				audit.AuditDateString = Vgr.util.dateToString(new Date(audit.AuditDate));
				audit.ExpirationDateString = Vgr.util.dateToString(new Date(audit.ExpirationDate));
				fillTypeInfo(audit);
				fillStatusInfo(audit);
				fillClassificationInfo(audit);
				fillLevelInfo(audit);
				fillClientNameInfo(audit);
			}
		}

		function fillTypeInfo(audit) {
			if (audit.IsDefault) {
				audit.TypeDescription = $scope.labels.GENERAL_AUDIT;
			} else {
				audit.TypeDescription = $scope.labels.INTERNAL_AUDIT;
			}
		}

		function fillStatusInfo(audit) {
			switch (audit.Status) {
				case Vgr.enumerations.audit.Status.Pending:
					audit.StatusDescription = $scope.labels.PENDING;
					break;
				case Vgr.enumerations.audit.Status.Valid:
					audit.StatusDescription = $scope.labels.VALID_AUDIT;
					break;
				case Vgr.enumerations.audit.Status.Canceled:
					audit.StatusDescription = $scope.labels.CANCELED_AUDIT;
					break;
				case Vgr.enumerations.audit.Status.Expired:
					audit.StatusDescription = $scope.labels.EXPIRED_AUDIT;
					break;
				case Vgr.enumerations.audit.Status.WaitingSupplier:
					audit.StatusDescription = $scope.labels.WAITING_SUPPLIER_AUDIT;
					break;
			}
		}

		function fillClassificationInfo(audit) {
			audit = auditService.loadClientClassification(audit);
		}

		function fillLevelInfo(audit) {
			audit.LevelDescription = $rootScope.labels.LEVEL + " " + audit.Level;
		}

		function fillClientNameInfo(audit) {
			if (audit.Owner.IsCompany) {
				audit.ClientName = audit.Owner.CompanyName;
			} else {
				audit.ClientName = audit.Owner.UnitName;
			}
		}

		$scope.$on(Vgr.constants.evtConfirmYes, function (event, data) {
			if (
				data &&
				data.id &&
				(data.text == $rootScope.labels.CANCEL_AUDIT_CONFIRMATION ||
					data.text == $rootScope.labels.DELETE_AUDIT_CONFIRMATION)
			) {
				auditService.deleteSupplierAudit(data.id).then(
					function () {
						loadAudits();
						$rootScope.$broadcast(Vgr.constants.evtAuditsChanged);
					},
					function () {}
				);
			}
		});

		function goActionPlanEdit(actionPlanId) {
			$state.go("actionPlanEdit", { supplierId, actionPlanId, supplierCode: $scope.transporterCode });
		}

		$scope.reloadAudits = function () {
			loadAudits();
		};

		function createActionPlan(clientAuditId) {
			const dto = { supplierId, clientAuditId };
			const deferred = $q.defer();
			httpService.postDTOToServiceV2(actionPlanService.createActionPlan, dto).then(
				function (actionPlanId) {
					deferred.resolve(actionPlanId);
				},
				function () {
					deferred.reject();
				}
			);
			return deferred.promise;
		}
	}
]);
