Vgr.enumerations = {
	validation: {
		DriverLicenseWithoutExpirationDate: 1,
		DriverLicenseExpired: 2,
		DriverWithoutMopp: 3,
		DriverMoppExpired: 4,
		VehicleWithoutInmetroCertificate: 5,
		VehicleWithInmetroCertificateExpired: 6,
		NoAuthDocumentSelected: 7,
		AuthDocumentDontContemplatesAllResidues: 8,
		AuthDocumentExpired: 9,
		ResidueQuantityExceededAuthValue: 10,
		AuthDocumentWithDifferentDisposalTechnology: 11,
		AuthDocumentWithDifferentRecipient: 12,
		ResidueQuantityIsRequired: 13,
		ResidueMeasureUnitInvalid: 14,
		TransporterIsRequired: 15,
		NoCadriSelected: 16,
		CadriDocumentExpired: 17,
		CadriDocumentDontContemplatesAllResidues: 18,
		ResidueWithoutIbamaCode: 19,
		AuditDestinatorLevelInvalid: 20,
		AuditDestinatorClassificationInvalid: 21,
		AuditTransporterLevelInvalid: 22,
		AuditTransporterClassificationInvalid: 23,
		ResidueRequired: 28,
		RecipientRequired: 30,
		DisposalTechRequired: 32,
		RequestDateRequired: 48,
		FutureDateNotAllowed: 49,
		RecipientQuantityIsRequired: 76,
		TemporaryStoragerRequired: 93,
		TemporaryStoragerNotFound: 94,
		TemporaryStoragerAuditLevelInvalid: 95,
		TemporaryStoragerAuditClassificationInvalid: 96,
		DisposalWithExternalIntegrationCantChangeDestinator: 98,
		QuantityBelowMinimumQuantity: 108,
		QuantityAboveMaximumQuantity: 109,
		RequestDateBeforeMinAllowedDate: 110,
		GatheringDateBeforeMinAllowedDate: 111,
		LimitDaysForDisposalEditionExceeded: 112,
		LimitDaysForDisposalCreationExceeded: 113,
		ResidueMustBeActive: 116,
		GatheringIsLocked: 117,
		AuthDocumentInactive: 134,
		CadriDocumentInactive: 135
	},

	replicateType: {
		Residue: 1,
		Supplier: 2
	},

	reportFilters: {
		periodEnum: {
			TotalInPeriod: "Total",
			GroupedByMonth: "ByMonth"
		},
		measureUnitEnum: {
			UseDataSaved: "UseDataSaved",
			ConvertToDefaultMeasureUnitInGroup: "ConvertToDefaultMeasureUnitInGroup",
			ConvertToSpecificUnit: "ConvertToSpecificUnit"
		},
		costsEnum: {
			Residue: "Residue",
			Shipping: "Shipping",
			Other: "Others"
		}
	},

	actionPlan: {
		Status: {
			Created: "Created",
			Pending: "Pending",
			Finalized: "Finalized",
			Canceled: "Canceled"
		}
	},

	audit: {
		Type: {
			Default: 1,
			Custom: 2
		},
		Status: {
			Pending: 1,
			Valid: 2,
			Canceled: 3,
			Expired: 4,
			WaitingSupplier: 6,
			Inactive: 8
		},
		Classification: {
			Approved: 1,
			ApprovedWithRestrictions: 2,
			Disapproved: 3,
			None: 4
		},
		ClassificationText: {
			Approved: "Approved",
			ApprovedWithRestrictions: "ApprovedWithRestrictions",
			Disapproved: "Disapproved",
			None: "None"
		},
		Item: {
			Classification: {
				RLI: 1,
				I: 2,
				F: 3,
				A: 4
			}
		},
		ClientAuditType: {
			General: 1,
			Internal: 2
		},
		AuditSolicitationType: {
			Shared: 1,
			Custom: 2
		},
		Notification: {
			Effect: 1,
			SupplierSolicitation: 2
		},
		Level: {
			Level1: 1,
			Level2: 2,
			Level3: 3,
			None: 4
		}
	},

	goal: {
		type: {
			ResidueGeneration: "ResidueGeneration",
			DisposalFinancial: "DisposalFinancial"
		}
	},

	removeAlert: {
		type: {
			Kpis: "Kpis",
			RelativeKpis: "RelativeKpis"
		}
	},

	request: {
		ResidueInformationType: {
			Text: 1,
			CheckBox: 2
		},
		NegotiationType: {
			Sell: 1,
			Treat: 2,
			SellOrTreat: 3
		},
		Status: {
			AtAuction: 1,
			Processing: 2,
			WinnerFound: 3,
			WinnerNotFound: 4,
			Canceled: 5,
			PendingEvaluation: 6,
			Finalized: 7
		},
		Periodicity: {
			Punctual: 1,
			Weekly: 2,
			Monthly: 3
		}
	},

	measureUnit: {
		Group: {
			Volume: 1,
			Mass: 2,
			Unit: 3,
			Power: 4,
			Distance: 5
		},
		Unit: {
			Kg: 108,
			Un: 110
		}
	},

	events: {
		cancelBid: "CANCEL_BID"
	},

	license: {
		Type: {
			License: 1,
			Cadri: 2
		}
	},

	loginState: {
		notLogged: 1,
		loggingIn: 2,
		loggingInOnIdp: 6,
		loggingOut: 3,
		logged: 4,
		loginError: 5,
		loggedNewPasswordRequired: 7
	},

	residue: {
		Class: {
			ClassI: 1,
			ClassIIA: 2,
			ClassIIB: 3
		},
		File: {
			Pictogram: 1,
			Image: 2,
			CharacterizationReport: 3
		},
		StickerPrintMode: {
			Single: 0,
			ByUnit: 1,
			SingleOrByUnit: 2
		}
	},

	authDocument: {
		treatmentType: {
			Treatment: 1,
			FinalDestination: 2,
			Automatic: 3
		}
	},

	clientEdit: {
		tabPage: {
			general: 0,
			additionalData: 1,
			production: 2,
			disposalPreferences: 3,
			storagePreferences: 4,
			integrations: 5,
			stockControlPreferences: 6
		}
	},

	supplier: {
		situation: {
			Regular: 1,
			Irregular: 2,
			Inactive: 3
		},
		status: {
			Regular: "Regular",
			Irregular: "Irregular",
			Inactive: "Inactive"
		},
		type: {
			Destinator: 1,
			Transporter: 2,
			TemporaryStorager: 3
		},
		tabPageRoute: {
			general: "",
			authDocuments: "documentos-autorizacao",
			audits: "auditorias",
			clientAudits: "auditorias-clientes",
			actionPlans: "planos-de-acao",
			clientActionPlans: "planos-de-acao-clientes"
		}
	},

	organization: {
		contractType: {
			Payer: 1,
			NotPayer: 2,
			Trial: 3
		}
	},

	pricing: {
		pricingType: {
			Residue: 1,
			Disposal: 2
		},
		negotiationType: {
			Sale: 1,
			Treatment: 2,
			Donation: 3
		},
		residuePricingType: {
			ByResidue: 1,
			ByDisposal: 2,
			ByResidueAndDisposal: 3
		}
	},

	pendency: {
		//----------------------------------------------
		//Suppliers
		CadrisToExpire: 10,
		CadrisExpired: 11,
		CadrisWithProtocol: 12,
		SuppliersWithExpiredDriversLicense: 16,
		SuppliersWithDriversLicenseToExpire: 17,
		SuppliersWithExpiredDriversMOPP: 18,
		SuppliersWithDriversMOPPToExpire: 19,
		SuppliersWithExpiredVehicleInmetroCertificate: 20,
		SuppliersWithVehicleInmetroCertificateToExpire: 21,
		ExpiredActionPlanItem: 29,
		CloseExpirationActionPlanItem: 30,
		ExpiredAudit: 31,
		CloseExpirationAudit: 32,
		SupplierDocumentsPendingApproval: 33,
		DocumentToExpire: 34,
		DocumentExpired: 35,
		DocumentWithProtocol: 36,
		RequiredDocumentMissing: 37,
		WaitingForSupplierAudit: 44,
		WithoutCtfDocument: 54,
		WithoutAudits: 55,
		ActionPlanMandatory: 56,
		//----------------------------------------------

		PendingInternalGatherings: 22,
		ResidueStorageAlert: 26,
		RequestedDisposals: 27,
		PendingDisposals: 28
	},

	scaling: {
		type: {
			OneStep: 1,
			TwoSteps: 2,
			TwoStepsWithStorage: 3
		},
		process: {
			Storage: 1,
			Disposal: 2
		}
	},

	gridSimple: {
		defaultActions: {
			add: "add",
			delete: "delete"
		},
		columnType: {
			date: "date",
			number: "number"
		}
	},

	customReport: {
		type: {
			Kpi: "Kpi",
			Goal: "Goal"
		},
		information: {
			ResidueGenerationQuantity: "DDE507B1-EC5E-4A5C-B263-272F775D52FD",
			ResidueDisposalQuantity: "8B8AE234-C55F-46B5-A2D0-F7C634B3B23E",
			ResidueDisposalPrice: "C3215B71-71E0-45EE-9B93-7CCAA2E6F1C7",
			ResidueDisposalRecipientEfficiency: "A704987F-01AF-4644-8555-BFC8A2EA8213",
			ProductionQuantity: "CC1A319B-1023-4532-BDCA-4CC8D05E2E4D"
		},
		dateRangeOption: {
			Custom: "Custom",
			LastMonth: "LastMonth",
			CurrentMonth: "CurrentMonth",
			LastYear: "LastYear",
			CurrentYear: "CurrentYear",
			Last12Months: "Last12Months"
		},
		dateRangeGrouping: {
			Total: "Total",
			ByMonth: "ByMonth"
		},
		measureUnitPreferenceOption: {
			UseDataSaved: "UseDataSaved",
			ConvertToDefaultMeasureUnitInGroup: "ConvertToDefaultMeasureUnitInGroup",
			ConvertToSpecificUnit: "ConvertToSpecificUnit"
		}
	},

	externalSystemLoginStatus: {
		Pending: "Pending",
		Error: "Error",
		Ok: "Ok"
	},
	customizedFilter: {
		type: {
			Date: 1,
			Select: 2,
			Text: 3,
			TextComplete: 4,
			KeyValue: 5,
			Metadata: 6,
			Chips: 7,
			MultiSelect: 8,
			State: 9,
			Residue: 10,
			GroupingOption: 11,
			MetadataGrouping: 12,
			DisposalTech: 13,
			Supplier: 14,
			Transporter: 15,
			City: 16,
			ResidueClass: 17,
			TemporaryStorager: 18,
			ClientRecipient: 19,
			ClientResidue: 20,
			ClientUser: 21,
			Number: 22,
			TextCompletePaginateServer: 23,
			SupplierTextCompleteV2: 24,
			AreaSelect: 25
		}
	},

	customizedFilterV2: {
		optionField: {
			type: {
				common: 1,
				additionalProperty: 2
			},
			value: {
				location: {
					city: "location.city",
					state: "location.state",
					organizationUnitId: "location.organizationUnit.id",
					organizationUnitProperty: "location.organizationUnit.property",
					organizationUnitDocument: "location.organizationUnit.document",
					area: "location.area.id",
					areaName: "location.area.name",
					areaProperty: "location.area.property"
				},
				residue: {
					id: "residue.id",
					name: "residue.name",
					ibama: "residue.ibama",
					class: "residue.class",
					physicalState: "residue.physicalState",
					tag: "residue.tag"
				},
				disposal: {
					recipient: "disposal.recipient",
					temporaryStorager: "disposal.temporaryStorager",
					destinator: "disposal.destinator",
					property: "disposal.property",
					priceType: "disposal.priceType",
					disposalType: "disposal.disposalType",
					transporter: "disposal.transporter"
				},
				residueGeneration: {
					recipient: "generation.recipient",
					property: "generation.property"
				},
				production: {
					product: "production.product"
				}
			}
		}
	},

	dateFilter: {
		groupings: {
			Month: 1,
			Total: 2
		}
	},

	reportExtension: {
		Excel: 1,
		Pdf: 2,
		Word: 3,
		Csv: 4
	},

	httpErrorCode: {
		BadRequest: 400
	},

	additionalProperty: {
		type: {
			FreeText: "Text",
			SuspendList: "List"
		},
		process: {
			InternalGathering: "ResidueGeneration",
			Disposal: "Disposal",
			Area: "Area",
			OrganizationUnit: "OrganizationUnit"
		}
	},
	conama313: {
		type: {
			DangerousAndNotDangerousResidues: 1,
			Recipient: 2,
			UncommonDestinatios: 3,
			RecycleTreatmment: 4,
			CommonDestinatios: 5
		}
	},
	destination: {
		model: {
			Complete: 1,
			Simplified: 2,
			Export: 3,
			TemporaryStorage: 4
		},

		status: {
			Requested: 1,
			Gathered: 2,
			Received: 3,
			Pending: 4,
			Finalized: 5,
			TemporaryStorageReceived: 6
		},

		invoiceStatus: {
			Valid: 1,
			Canceled: 2
		},

		deleteStatus: {
			PendingCancelIntegrations: 1,
			PendingDelete: 2,
			NotPossible: 3
		},

		disposalCostType: {
			CostByDisposal: 2,
			CostByResidue: 1
		},

		pendencyType: {
			MTR: 1,
			CDF: 2,
			Invoice: 3
		},

		report: {
			FDSRReport: 1,
			EmergencySheet: 2,
			EmergencyEnvelope: 3,
			TransportChecklist: 4,
			IdentificationLabel: 5,
			FinalDestinationCertificate: 6,
			ResidueTransportManifest: 7,
			ProvisionalMTR: 8,
			MtrReceipt: 9
		},

		notification: {
			Destinator: 1,
			Transporter: 2,
			MTR: 3
		},

		file: {
			Invoice: 1,
			GeneralDocument: 2,
			Manifest: 3,
			CDF: 4,
			ComplementaryMtr: 5
		},

		calculatedDisposalFileTypes: {
			Invoice: "Invoice",
			GeneralDocument: "GeneralDocument",
			Manifest: "Manifest",
			DisposalCertificate: "DisposalCertificate",
			ComplementaryMtr: "ComplementaryMtr"
		},

		systemSource: {
			Local: 1,
			FEAM_MG: 2,
			INEA_RJ: 3,
			FEPAM_RS: 4,
			IMA_SC: 5,
			SINIR_BR: 6,
			SIGOR_SP: 7,
			SGA_PR: 8,
			IEMA_ES: 9,
			COLETAS_SP: 10,
			SGORS_AL: 11
		},

		systemStatus: {
			Pending: 1,
			Created: 2,
			Received: 3,
			Disposed: 4,
			Canceled: 5,
			Provisional: 6,
			ReceivedTemporaryStorage: 7,
			ComplementaryMTREmitted: 8,
			PendingManualAction: 9,
			Refused: 10
		},

		calculatedSystemStatus: {
			Pending: "Pending",
			Created: "Created",
			Received: "Received",
			Disposed: "Disposed",
			Canceled: "Canceled",
			Provisional: "Provisional",
			ReceivedTemporaryStorage: "ReceivedTemporaryStorage",
			ComplementaryMTREmitted: "ComplementaryMTREmitted",
			PendingManualAction: "PendingManualAction",
			Refused: "Refused",
			Processing: "Processing",
			PendingConfirmReceival: "PendingConfirmReceival"
		},

		systemActions: {
			ViewDetails: "ViewDetails",
			UpdateStatus: "UpdateStatus",
			Cancel: "Cancel",
			PrintMtr: "PrintMtr"
		},

		stages: {
			Solicitation: 0,
			Gathering: 1,
			Tracking: 2,
			InTemporaryStorage: 3,
			Arrival: 4,
			Confirmation: 5
		},

		steps: {
			A: 0,
			B: 1,
			C: 2,
			D: 3,
			E: 4,
			F: 5,
			G: 6,
			H: 7
		},

		alertMessages: {
			invalidGatheringDate: 0,
			gatheringIsLocked: 1,
			destinatorPendenciesAlert: 2
		},

		validationType: {
			Driver: 0,
			Vahicle: 1,
			Document: 2
		},

		validations: {
			suppliers: {
				key: "SupplierType",
				atuation: {
					Destinator: "Destinador",
					Transporter: "Transportador",
					TemporaryStorager: "Armazenador Temporário"
				}
			}
		}
	},

	stockControl: {
		adjustmentTypes: {
			DecreaseResidueGeneration: 1,
			MaintainResidueGeneration: 2
		},

		controlType: {
			ExactQuantity: 1,
			RecipientQuantity: 2
		}
	},

	months: {
		JANUARY: 1,
		FEBRUARY: 2,
		MARCH: 3,
		APRIL: 4,
		MAY: 5,
		JUNE: 6,
		JULY: 7,
		AUGUST: 8,
		SEPTEMBER: 9,
		OCTOBER: 10,
		NOVEMBER: 11,
		DECEMBER: 12
	},

	operationStatus: {
		Pending: 1,
		Processing: 2,
		Success: 3,
		UnexpectedError: 4,
		Forbidden: 5,
		BadRequestError: 6
	},

	systemPermissions: {
		types: {
			Master: 0,
			Organization: 1,
			OrganizationUnit: 2
		},
		typesText: {
			Master: "Master",
			Organization: "Organization",
			OrganizationUnit: "OrganizationUnit"
		},
		permissionActionTypes: {
			Create: 1,
			Delete: 2
		},
		level: {
			Allow: 1,
			NotAllow: 2,
			ToBeDefined: 3
		}
	},

	ibamaRapp: {
		status: {
			Pending: 1,
			ConfirmPending: 2,
			Success: 3,
			Error: 4
		},

		residue: {
			status: {
				Pending: 1,
				Success: 2,
				InternalError: 3,
				DataError: 4
			}
		}
	},

	internalGathering: {
		maximumAmountOfUnitsWhenCreating: 50,
		originResidue: {
			Production: "Production",
			Process: "Process"
		},
		status: {
			Requested: 1,
			Stored: 2,
			PartiallyDisposed: 3,
			Disposed: 4
		},
		messagesType: {
			permission: 1,
			alert: 2
		},
		internalTransferType: {
			NoTransfer: "NoTransfer",
			TwoStepTransfer: "TwoStepTransfer",
			FullTransfer: "FullTransfer"
		}
	},

	userAccess: {
		Enabled: 1,
		Disabled: 2,
		Hidden: 3
	},

	userInviteMode: {
		sso: 0,
		email: 1,
		username: 2
	},

	kpiDataType: {
		GenerationQuantity: "GenerationQuantity",
		DisposalQuantity: "DisposalQuantity",
		DisposalCost: "DisposalCost",
		ProductionQuantity: "ProductionQuantity",
		BuiltArea: "BuiltArea"
	},
	messageTypes: {
		REDIRECT: "REDIRECT",
		REDIRECT_SINGLETON: "REDIRECT_SINGLETON",
		SAVE_STOCK_CONTROL: "SAVE_STOCK_CONTROL",
		REPLICATE_STOCK_CONTROL: "REPLICATE_STOCK_CONTROL",
		SYNC_LOCAL_STORAGE: "SYNC_LOCAL_STORAGE",
		SET_LANGUAGE: "SET_LANGUAGE",
		SET_BEARER_TOKEN: "SET_BEARER_TOKEN",
		SAVE_ORGANIZATION_SUPPLIER_PREFERENCES: "SAVE_ORGANIZATION_SUPPLIER_PREFERENCES",
		SAVE_DISPOSAL_PREFERENCES: "SAVE_DISPOSAL_PREFERENCES",
		REPLICATE_DISPOSAL_PREFERENCES: "REPLICATE_DISPOSAL_PREFERENCES",
		SAVE_STORAGE_PREFERENCES: "SAVE_STORAGE_PREFERENCES",
		REPLICATE_STORAGE_PREFERENCES: "REPLICATE_STORAGE_PREFERENCES"
	},
	newFeaturesKey: {
		mtrEmission: "newFeatures_gatherDisposalOnCreateFromModel",
		ctfIntegration: "newFeatures_integrationCtfIbamaDivulgation",
		sgorsIntegration: "newFeatures_sgorsIntegration",
		newStorageModule: "newFeatures_newStorageModuleDivulgation",
		disposalCostsModule: "newFeatures_disposalCostsModule",
		notifySupplierExpiringModule: "newFeatures_notifySupplierExpiringModule",
		internalTransferControl: "newFeatures_internalTransferControlDivulgation",
		removeOldGatherinPage: "newFeatures_removeOldGatherinPage",
		residueRastreabilityReport: "newFeatures_residueRastreabilityReport",
		biModule: "newFeatures_biModule",
		enhancedMtrMonitoringScreen: "newFeatures_enhancedMtrMonitoringScreen",
		marketingSurvey: "newFeatures_marketingSurvey"
	},
	newFeaturesType: {
		mtrEmission: 0,
		ctfIntegration: 1,
		sgorsIntegration: 3,
		newStorageModule: 4,
		disposalCostsModule: 5,
		notifySupplierExpiringMtr: 6,
		internalTransferControl: 7,
		removeOldGatherinPage: 8,
		residueRastreabilityReport: 9,
		biModule: 10,
		enhancedMtrMonitoringScreen: 11,
		marketingSurvey: 12
	},
	channel: {
		disposal: {
			dataLoad: 1,
			externalAPI: 2,
			disposalScreenWithModel: 3,
			disposalScreenWithoutModel: 4,
			mtrImport: 5
		}
	}
};
