angular.module("vgresiduos").controller("CreateAuditPopupCtrl", [
	"$scope",
	"$state",
	"httpService",
	"dialogService",
	"auditService",
	"permissionService",
	function ($scope, $state, httpService, dialogService, auditService, permissionService) {
		"use strict";

		const audit1Permission = permissionService.hasPermissionV2(Vgr.systemPermissions.SupplierOrganizationAuditLevel1);
		const audit2Permission = permissionService.hasPermissionV2(Vgr.systemPermissions.SupplierOrganizationAuditLevel2);
		const audit3Permission = permissionService.hasPermissionV2(Vgr.systemPermissions.SupplierOrganizationAuditLevel3);

		$scope.auditModelList = [];
		$scope.auditModelSelected = {};

		function getAuditLevelsDescriptions() {
			auditService.getAuditLevelsDescriptions().then(
				function (data) {
					createAuditLevels(data);
					setDefaultParams();
				},
				function (data) {}
			);
		}

		function createAuditLevels(auditLevelsDescriptions) {
			$scope.auditLevels = [];

			if (audit1Permission) {
				$scope.auditLevels.push({
					value: Vgr.enumerations.audit.Level.Level1,
					description: auditLevelsDescriptions.level1
				});
			}
			if (audit2Permission) {
				$scope.auditLevels.push({
					value: Vgr.enumerations.audit.Level.Level2,
					description: auditLevelsDescriptions.level2
				});
			}
			if (audit3Permission) {
				$scope.auditLevels.push({
					value: Vgr.enumerations.audit.Level.Level3,
					description: auditLevelsDescriptions.level3
				});
			}
		}

		function setDefaultParams() {
			if ($scope.auditLevels.length) {
				$scope.auditLevelSelected = $scope.auditLevels[0].value;
			}
			$scope.auditGeneral = false;
		}

		$scope.close = function () {
			dialogService.hide($scope.dialog);
		};

		$scope.toAuditEdit = function () {
			const dto = {
				AuditModelId: $scope.auditModelSelected.id,
				Level: $scope.auditLevelSelected
			};

			console.log($scope);
			httpService.postDTOToServiceV2(auditService.createSupplierAudit, dto).then(function (data) {
				$state.go("auditEdit", { supplierId: $scope.transporterCode, auditId: data });
			});
		};

		$scope.getDefaultOrCustomDescription = function (model) {
			if (model.isDefault) {
				return $scope.labels.DEFAULT;
			} else {
				return $scope.labels.CUSTOMA;
			}
		};

		$scope.disableContinue = function () {
			if (!$scope.auditModelSelected.id) {
				return true;
			}
			return false;
		};

		function listAuditModels() {
			httpService.getListFromServiceCore(auditService.listAuditModels).then(
				function (resp) {
					$scope.auditModelList = resp.list;
				},
				function () {
					//do nothing
				}
			);
		}

		function initializePopup() {
			getAuditLevelsDescriptions();
			listAuditModels();
		}

		initializePopup();
	}
]);
