angular.module("vgresiduos").controller("movimentationReportCtrl", [
	"$rootScope",
	"$scope",
	"clientService",
	function ($rootScope, $scope, clientService) {
		"use strict";

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;
			$scope.movimentationReportFilter = {};
			$scope.movimentationReportFilter.dateId = 1;
			$scope.movimentationReportDTO = {
				format: "PDF"
			};

			$scope.movimentationReportFilter.showDestinationData = true;

			$scope.reportOptions = new Cmp.constructors.ReportOptionsV2(
				$rootScope.labels.RESIDUE_MOVIMENTATION_REPORT_TITLE,
				clientService.generateMovimentationReport,
				$scope.movimentationReportDTO
			);

			$scope.intervalTypeList = [
				{
					ID: 1,
					Description: $rootScope.labels.CURRENT_MONTH
				},
				{
					ID: 2,
					Description: $rootScope.labels.CURRENT_YEAR
				},
				{
					ID: 3,
					Description: $rootScope.labels.CUSTOM
				}
			];

			$scope.generateReport = function () {
				if ($scope.validationFields()) {
					formatMovimentationReportFilter();
					if ($scope.reportOptions) {
						$scope.reportOptions.filter = $scope.movimentationReportDTO;
						$scope.options.filter = $scope.movimentationReportDTO;
					}
					$scope.reportOptions.onFilterClick();
				}
			};

			$scope.validationFields = function () {
				if ($scope.movimentationReportFilter.dateId == 3) {
					if (!$scope.movimentationReportFilter.beginDate && !$scope.movimentationReportFilter.endDate) {
						$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels["ERR_USR_0005"]);
						return false;
					}
				}
				return true;
			};
		};

		const formatMovimentationReportFilter = function () {
			$scope.movimentationReportDTO = {
				PeriodID: $scope.movimentationReportFilter.dateId,
				InitDate: $scope.movimentationReportFilter.beginDate
					? Vgr.date.fromDateToUTCString($scope.movimentationReportFilter.beginDate)
					: null,
				EndDate: $scope.movimentationReportFilter.endDate
					? Vgr.date.fromDateToUTCString($scope.movimentationReportFilter.endDate)
					: null,
				ShowDailyProduction: $scope.movimentationReportFilter.showDailyProduction,
				Format: $scope.movimentationReportFilter.format,
				AreaID: $scope.movimentationReportFilter.area ? $scope.movimentationReportFilter.area.id : ""
			};
		};
	}
]);
