angular.module("vgresiduos").factory("disposalService", [
	"$http",
	"$q",
	"$rootScope",
	"localStorageWithTimeoutService",
	"accountService",
	function ($http, $q, $rootScope, localStorageWithTimeoutService, accountService) {
		"use strict";

		const disposalTypesCacheKey = Vgr.constants.cacheKeysPrefix.disposalTypes;
		const disposalPendencyTypesCacheKey = Vgr.constants.cacheKeysPrefix.disposalPendencyTypes;
		const cacheTimeoutInMinutes = 43200;

		// disposal disposal

		const _getDisposal = function (id) {
			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.disposals + id
			};
			return $http(req);
		};

		const _createDisposal = function (model) {
			const deferred = $q.defer();

			const req = {
				method: "post",
				data: model,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.disposals
			};

			$http(req).then(
				function (data) {
					deferred.resolve(data);
				},
				function (data) {
					deferred.reject(data);
				}
			);

			return deferred.promise;
		};

		const _updateDisposal = function (dto) {
			const req = {
				method: "put",
				data: dto.model,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.disposals + dto.disposalId
			};
			return $http(req);
		};

		const _attachDisposalFiles = function (dto) {
			const req = {
				method: "post",
				data: dto.model,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.disposals + dto.disposalId + "/files"
			};
			return $http(req);
		};

		const _listDisposalNotifications = function (disposalId) {
			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.disposals + disposalId + "/notifications"
			};
			return $http(req);
		};

		const _sendDisposalNotification = function (dto) {
			const req = {
				method: "post",
				data: dto.model,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.disposals + dto.disposalId + "/notifications"
			};
			return $http(req);
		};

		const _listDisposalReportstoPrint = function (dto) {
			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.disposals + dto.DisposalId + "/reports"
			};
			return $http(req);
		};

		const _generateDisposalReports = function GenerateReports(dto) {
			const deferred = $q.defer();

			const req = {
				method: "post",
				data: dto.model,
				url: getBaseUrlResiduesManagement("generateDisposalDocuments").replace("[DISPOSAL_ID]", dto.DisposalId)
			};

			$http(req).then(
				function (data) {
					deferred.resolve(data);
				},
				function (data) {
					deferred.reject(data);
				}
			);

			return deferred.promise;
		};

		const _listDisposalPendencyTypes = function () {
			return Vgr.util.getWithCache(
				Vgr.constants.newHostUrl + Vgr.resources.apiV1.default + "disposal-pendency-types",
				null,
				disposalPendencyTypesCacheKey,
				cacheTimeoutInMinutes,
				localStorageWithTimeoutService,
				$http,
				$q
			);
		};

		const _listDisposalTypes = function () {
			return Vgr.util.getWithCache(
				Vgr.constants.coreHostUrl + Vgr.resources.core.disposalTypes,
				null,
				disposalTypesCacheKey,
				cacheTimeoutInMinutes,
				localStorageWithTimeoutService,
				$http,
				$q
			);
		};

		const _listDisposalStatus = function () {
			return [
				{
					Id: Vgr.enumerations.destination.status.Requested,
					Description: $rootScope.labels.DISPOSAL_STATUS_ENUM[Vgr.enumerations.destination.status.Requested]
				},
				{
					Id: Vgr.enumerations.destination.status.Gathered,
					Description: $rootScope.labels.DISPOSAL_STATUS_ENUM[Vgr.enumerations.destination.status.Gathered]
				},
				{
					Id: Vgr.enumerations.destination.status.Received,
					Description: $rootScope.labels.DISPOSAL_STATUS_ENUM[Vgr.enumerations.destination.status.Received]
				},
				{
					Id: Vgr.enumerations.destination.status.Pending,
					Description: $rootScope.labels.DISPOSAL_STATUS_ENUM[Vgr.enumerations.destination.status.Pending]
				},
				{
					Id: Vgr.enumerations.destination.status.Finalized,
					Description: $rootScope.labels.DISPOSAL_STATUS_ENUM[Vgr.enumerations.destination.status.Finalized]
				}
			];
		};

		const validationDtoHashDict = {};
		const _validateDisposal = function (dto) {
			const deferred = $q.defer();

			const dtoHash = objectHash(dto);
			if (validationDtoHashDict[dtoHash]) {
				deferred.resolve(validationDtoHashDict[dtoHash]);
				return deferred.promise;
			}

			const req = {
				method: "post",
				data: dto,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.default + "disposal-validations/"
			};

			const promise = $http(req);
			promise.then(function (response) {
				validationDtoHashDict[dtoHash] = response;
			});

			return promise;
		};

		function getErrorCode(errorMessage) {
			return errorMessage.Code ?? errorMessage.code;
		}

		function getErrorMessage(errorMessage) {
			return errorMessage.Message ?? errorMessage.message;
		}

		const _getIntegrationErrorMessageFromResponse = function (
			status,
			errors,
			systemDescription,
			includeDefaultMessage
		) {
			let defaultMessage = "";

			if (includeDefaultMessage) {
				defaultMessage = $rootScope.labels.MTR_SYSTEM_INTEGRATION_NOT_POSSIBLE;
			}

			if (errors && errors.length > 0) {
				const formattedErrorMessage = getFormattedErrorMessageFromErrors(
					defaultMessage,
					systemDescription,
					status,
					errors
				);
				if (formattedErrorMessage) {
					return formattedErrorMessage;
				}
			}

			return (
				defaultMessage + $rootScope.labels.DEFAULT_MTR_SYSTEM_ERROR_REASON.replace("[SYSTEM]", systemDescription || "")
			);
		};

		function getFormattedErrorMessageFromErrors(defaultMessage, systemDescription, status, errors) {
			const error = errors[0];
			const errorResponse = createResponseError(getErrorCode(error), getErrorMessage(error), status);

			if (errorResponse.isStatusBadRequest() && errorResponse.code && errorResponse.getErrorMessage()) {
				if (errorResponse.isErrorTemplate()) {
					return defaultMessage + errorResponse.getErrorMessage().replace("[SYSTEM]", systemDescription || "");
				} else {
					return defaultMessage + errorResponse.getErrorMessage();
				}
			}
		}

		function createResponseError(code, message, statusInternal) {
			return {
				code: code,
				message: message,
				status: statusInternal,
				getErrorMessage: function () {
					return $rootScope.labels[this.code] || this.message;
				},
				isErrorTemplate: function () {
					return (
						this.code === Vgr.errorCodes.disposal.SystemUnavailable ||
						this.code === Vgr.errorCodes.disposal.SystemInstable ||
						this.code === Vgr.errorCodes.disposal.SystemInstableOnCreation ||
						this.code === Vgr.errorCodes.disposal.WrongDestinatorType ||
						this.code === Vgr.errorCodes.disposal.WrongTransporterType
					);
				},
				isStatusBadRequest: function () {
					return this.status == Vgr.enumerations.httpErrorCode.BadRequest;
				}
			};
		}

		const _groupDisposalQuantitieByMeasureUnit = function (quantities) {
			return quantities.reduce((grouped, quantity) => {
				const measureUnitId = quantity.MeasureUnit.Id;
				grouped[measureUnitId] = grouped[measureUnitId] || [];
				grouped[measureUnitId].push(angular.copy(quantity));
				return grouped;
			}, []);
		};

		//V2

		const _listDisposalsForFollowup = function (params) {
			let parameters = "";
			if (params) {
				parameters = $.param(params);
			}

			const req = {
				method: "get",
				url:
					Vgr.constants.newHostUrl +
					Vgr.resources.apiV2.disposalsForFollowup.replace("[ORGANIZATION_UNIT]", params.organizationUnitCode) +
					"?" +
					parameters
			};
			return $http(req);
		};

		const _deleteDisposal = function (dto) {
			let parameters = "";
			if (dto.params) {
				parameters = $.param(dto.params);
			}

			const req = {
				method: "delete",
				url:
					Vgr.constants.newHostUrl +
					Vgr.resources.apiV2.disposal.replace("[ORGANIZATION_UNIT]", dto.organizationUnitCode) +
					dto.disposalId +
					"?" +
					parameters
			};
			return $http(req);
		};

		const _createDisposalIntegration = function (dto) {
			const req = {
				method: "post",
				data: dto.model,
				timeout: 240000,
				url:
					Vgr.constants.newHostUrl +
					Vgr.resources.apiV2.disposalIntegration
						.replace("[ORGANIZATION_UNIT]", dto.organizationUnitCode)
						.replace("[DISPOSAL]", dto.disposalId)
			};
			return $http(req);
		};

		const _cancelDisposalIntegration = function (dto) {
			const deferred = $q.defer();

			let parameters = "";
			if (dto.params) {
				parameters = $.param(dto.params);
			}

			const req = {
				method: "delete",
				timeout: 240000,
				url:
					Vgr.constants.newHostUrl +
					Vgr.resources.apiV2.disposalIntegration
						.replace("[ORGANIZATION_UNIT]", dto.organizationUnitCode)
						.replace("[DISPOSAL]", dto.disposalId) +
					dto.sourceId +
					"?" +
					parameters
			};
			$http(req).then(
				function (data) {
					deferred.resolve(data);
				},
				function (data) {
					deferred.reject(data);
				}
			);

			return deferred.promise;
		};

		const _temporaryStoragerReceiveDisposal = function (dto) {
			const req = {
				method: "put",
				data: dto.model,
				url:
					Vgr.constants.newHostUrl +
					Vgr.resources.apiV2.temporaryStoragerReceiveDisposal
						.replace("[ORGANIZATION_UNIT]", dto.organizationUnitCode)
						.replace("[DISPOSAL]", dto.disposalId)
			};
			return $http(req);
		};

		function getBaseUrlResiduesManagement(resourceKey, parameters = "") {
			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;
			const organizationUnitCode = client.code;

			const baseUrl = (Vgr.constants.residuesManagementHostUrl + Vgr.resources.residuesManagement[resourceKey])
				.replace("[ORGANIZATION]", organizationId)
				.replace("[ORGANIZATION_UNIT]", organizationUnitCode);

			if (parameters) {
				return baseUrl + "?" + parameters;
			}
			return baseUrl;
		}

		return {
			groupDisposalQuantitieByMeasureUnit: _groupDisposalQuantitieByMeasureUnit,
			getDisposal: _getDisposal,
			createDisposal: _createDisposal,
			updateDisposal: _updateDisposal,

			attachDisposalFiles: _attachDisposalFiles,
			listDisposalNotifications: _listDisposalNotifications,
			sendDisposalNotification: _sendDisposalNotification,
			listDisposalReportstoPrint: _listDisposalReportstoPrint,
			generateDisposalReports: _generateDisposalReports,

			listDisposalPendencyTypes: _listDisposalPendencyTypes,
			listDisposalTypes: _listDisposalTypes,
			listDisposalStatus: _listDisposalStatus,
			validateDisposal: _validateDisposal,
			getIntegrationErrorMessageFromResponse: _getIntegrationErrorMessageFromResponse,
			listDisposalsForFollowup: _listDisposalsForFollowup,
			deleteDisposal: _deleteDisposal,
			createDisposalIntegration: _createDisposalIntegration,
			cancelDisposalIntegration: _cancelDisposalIntegration,
			temporaryStoragerReceiveDisposal: _temporaryStoragerReceiveDisposal
		};
	}
]);
