angular.module("vgresiduos").controller("AreaCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"areaService",
	function ($scope, $rootScope, $q, httpService, areaService) {
		"use strict";

		const labels = $rootScope.labels;
		let isListLoaded;

		this.$onInit = function () {
			initLabel();

			$scope.helpInfo = $scope.$ctrl.helpInfo ? $scope.$ctrl.helpInfo : null;
			loadAreas().then(setArea);
		};

		function initLabel() {
			$scope.label = $scope.$ctrl.label ? $scope.$ctrl.label : labels.AREA;

			if ($scope.$ctrl.isRequired) {
				$scope.label += "*";
			}
		}

		function loadAreas() {
			const deferred = $q.defer();

			if (isListLoaded) {
				deferred.resolve();
				return deferred.promise;
			}

			const dto = {
				onlyActive: $scope.$ctrl.onlyActive ? true : false
			};

			httpService.getListFromServiceCore(areaService.listAreas, dto).then(
				function (response) {
					$scope.areas = response.list;
					isListLoaded = true;
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function setArea() {
			if ($scope.$ctrl.setterId) {
				let foundArea = $scope.areas.find((area) => area.code == $scope.$ctrl.setterId);
				if (foundArea) {
					$scope.$ctrl.model = foundArea;
				}
			} else if ($scope.$ctrl.selectAutoWhenOneOption && $scope.areas.length == 1) {
				$scope.$ctrl.model = $scope.areas[0];
			}
		}

		$scope.isDisabled = function () {
			return $scope.$ctrl.isDisabled ? $scope.$ctrl.isDisabled : false;
		};

		$scope.$watch("$ctrl.setterId", function (newValue, oldValue) {
			const modelId = $scope.$ctrl.model ? $scope.$ctrl.model.code : null;

			if (newValue != modelId) {
				loadAreas().then(setArea);
			}
		});
	}
]);
