angular.module("vgresiduos").controller("ClientTextCompleteCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"clientService",
	function ($scope, $rootScope, $q, httpService, clientService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			$scope.listClients($scope.$ctrl.setterId).then(setClient);
		};

		$scope.listClients = function (setterId) {
			const dto = {
				query: $scope.paginationOptions.query,
				skip: $scope.paginationOptions.pageNumber - 1,
				take: $scope.paginationOptions.pageSize
			};
			if ($scope.$ctrl.permissionId) {
				dto.systemPermissionId = $scope.$ctrl.permissionId;
			}

			if (setterId && (typeof setterId === "string" || typeof setterId === "number")) {
				dto.id = setterId;
			} else {
				delete dto.id;
			}

			const deferred = $q.defer();
			httpService.getListFromServiceCore(clientService.listClientsCore, dto).then(
				function (response) {
					response.list.forEach((element) => {
						element.DescriptionToShow = element.id + " - " + element.name;
					});
					if ($scope.$ctrl.excluded) {
						response.list = response.list.filter((u) => !$scope.$ctrl.excluded.includes(u.id));
					}
					$scope.clients = response.list;
					deferred.resolve(response.list);
				},
				function () {
					deferred.reject();
				}
			);
			return deferred.promise;
		};

		$scope.paginationOptions = {
			pageSize: 20,
			pageNumber: 1,
			query: ""
		};

		$scope.subfieldList = [
			{
				item: "code",
				label: $scope.labels.ID
			}
		];

		$scope.onChange = function (client) {
			if ($scope.$ctrl.onChange) {
				$scope.$ctrl.onChange(client);
			}
		};

		function setClient() {
			if (!$scope.$ctrl.setterId) {
				$scope.searchText = null;
				$scope.$ctrl.model = null;
				return;
			}
			const foundClient = $scope.clients.find((c) => c.id == $scope.$ctrl.setterId);
			if (foundClient != null) {
				$scope.$ctrl.model = foundClient;
			}
		}

		const watchSetterId = $scope.$watch("$ctrl.setterId", function (newValue, _oldValue) {
			const modelId = $scope.$ctrl.model ? $scope.$ctrl.model.id : null;

			if (newValue != modelId) {
				$scope.listClients().then(setClient);
			}
		});

		$scope.$on("$destroy", function () {
			// disable the listener
			watchSetterId();

			// nullify the DOM-bound model
			$scope.domElement = null;
		});
	}
]);
