angular.module("vgresiduos").controller("CustomReportCtrl", [
	"$rootScope",
	function ($rootScope) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, "bi/relatorios");
		};
	}
]);
