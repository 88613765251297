angular.module("vgresiduos").controller("AuditCategoryListCtrl", [
	"$rootScope",
	"$scope",
	"httpService",
	"auditService",
	"dialogService",
	"$state",
	"actionPlanService",
	"$sce",
	function ($rootScope, $scope, httpService, auditService, dialogService, $state, actionPlanService, $sce) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.actionPlan = null;
		$scope.approvedByActionPlan = false;
		$scope.hasActiveActionPlan = false;
		$scope.generalStatus = Vgr.constants.generalStatus;

		this.$onInit = function () {
			if ($scope.ctrl.audit && $scope.ctrl.audit.Categories) {
				setEditOptions();
				setCategoryListMenu();
				setRadioOptions();
				loadActionPlan();
			}
		};

		function setCategoryListMenu() {
			for (let index in $scope.ctrl.audit.Categories) {
				$scope.ctrl.audit.Categories[index].Toggled = true;
				countFilledItems($scope.ctrl.audit.Categories[index]);
			}
		}

		function setEditOptions() {
			if (!$scope.ctrl.isEdition) {
				$scope.disableEdit = true;
				$scope.attachmentLabel = $scope.labels.SEE_ATTACHMENT;
			} else {
				$scope.attachmentLabel = $scope.labels.EDIT_ATTACHMENT;
			}
		}

		function countFilledItems(category) {
			let count = 0;
			for (let categoryItem of category.Items) {
				if (categoryItem.OptionId) {
					count += 1;
				}
			}
			category.FilledItems = count;
		}

		function setRadioOptions() {
			httpService.getListFromServiceCore(auditService.listAuditCategoryItemOptions, null).then(
				function (response) {
					$scope.radioOptions = response.list;
				},
				function () {}
			);
		}

		function loadActionPlan() {
			const dto = {
				queryParams: {
					clientAuditId: $scope.ctrl.audit.Id
				},
				supplierId: $scope.ctrl.audit.AuditedClientId
			};

			httpService.getListFromServiceCore(actionPlanService.listActionPlans, dto).then(function (response) {
				$scope.actionPlan = response.list[0] || null;
				if ($scope.actionPlan) {
					$scope.approvedByActionPlan =
						$scope.ctrl.audit.Classification === Vgr.enumerations.audit.Classification.Approved &&
						$scope.actionPlan.status === Vgr.enumerations.actionPlan.Status.Finalized;
					$scope.hasActiveActionPlan =
						$scope.actionPlan.status === Vgr.enumerations.actionPlan.Status.Created ||
						$scope.actionPlan.status === Vgr.enumerations.actionPlan.Status.Pending;
				} else {
					$scope.approvedByActionPlan = false;
					$scope.hasActiveActionPlan = false;
				}
			});
		}

		$scope.changeRadioOption = function (category) {
			countFilledItems(category);
		};

		$scope.showOrHideCategoryItems = function (category) {
			category.Toggled = !category.Toggled;
		};

		$scope.openAttachmentPopup = function (item) {
			const params = {
				attachmentList: item.Attachments,
				attachmentPath: "Audits/" + $scope.ctrl.audit.Id + "/Items/" + item.Id,
				observations: item.Observations,
				observationsDescription: $scope.ctrl.isEdition
					? $scope.labels.ATTACHMENT_PUPUP_TEXT_EDIT
					: $scope.labels.ATTACHMENT_PUPUP_TEXT_VIEW,
				isEditMode: $scope.ctrl.isEdition,
				hasDocumentCreatePermission: $scope.ctrl.hasDocumentCreatePermission,
				supplierId: $scope.ctrl.audit.AuditedClientId,
				supplierCode: $scope.ctrl.supplierCode,
				documentIds: item.Documents,
				documentsEnabled: true
			};

			dialogService
				.showDialogFromTemplateV2(
					"views/components/popups/attachmentPopup_template.html",
					"AttachmentPopupCtrl",
					null,
					params,
					false
				)
				.then(
					function (data) {
						item.Attachments = data.attachmentList;
						item.Observations = data.observations;
						item.Documents = data.documentIds;
					},
					function () {}
				);
		};

		$scope.hasContent = function (item) {
			return (
				item.Observations ||
				(item.Attachments && item.Attachments.length > 0) ||
				(item.Documents && item.Documents.length > 0)
			);
		};

		$scope.goToActionPlanEditPage = function () {
			$state.go("actionPlanEdit", {
				supplierId: $scope.ctrl.audit.AuditedClientId,
				actionPlanId: $scope.actionPlan.id,
				corporateOrigin: $scope.ctrl.corporateOrigin,
				supplierCode: $scope.ctrl.supplierCode
			});
		};

		$scope.formatMessage = function (message) {
			if (!message) {
				return "";
			}

			return $sce.trustAsHtml(message.replaceAll("\\r\\n", "<br />"));
		};
	}
]);
