angular.module("vgresiduos").factory("auditService", [
	"$rootScope",
	"$http",
	"$q",
	"preferencesService",
	"permissionService",
	"accountService",
	function ($rootScope, $http, $q, preferencesService, permissionService, accountService) {
		"use strict";

		const _getSupplierAuditById = function getSupplierAuditById(auditId) {
			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.audits + auditId
			};
			return $http(req);
		};

		const _listSupplierAudits = function listSupplierAudits(dto) {
			return _listAuditsAsAuditor({
				auditedClientId: dto.auditedClientId,
				onlyActive: dto.onlyActive,
				status: dto.status
			});
		};

		const _listAuditsAsAuditor = function listAuditsAsAuditor(dto) {
			dto.auditAsAuditor = true;

			return _listAudits(dto);
		};

		const _listAuditsAsAudited = function listAuditsAsAudited(dto) {
			dto.auditAsAuditor = false;

			return _listAudits(dto);
		};

		const _listAudits = function listAudits(dto) {
			let parameters = "";
			parameters = $.param(dto);

			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.audits + "?" + parameters
			};
			return $http(req);
		};

		const _getAuditNotification = function (dto) {
			let parameters = "";
			const data = {
				observations: dto.Observations,
				supplierName: dto.SupplierName
			};
			parameters = $.param(data);
			const req = {
				method: "get",
				url:
					Vgr.constants.newHostUrl +
					Vgr.resources.apiV1.audits +
					dto.AuditId +
					"/notifications/" +
					dto.Type +
					"?" +
					parameters
			};
			return $http(req);
		};

		const _transferAuditControl = function (dto) {
			const model = {
				observations: dto.Observations,
				supplierName: dto.SupplierName,
				supplierEmail: dto.SupplierEmail
			};
			const req = {
				method: "post",
				data: model,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.audits + dto.AuditId + "/control-transfer"
			};
			return $http(req);
		};

		const _createSupplierAudit = function (dto) {
			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;
			const req = {
				method: "post",
				data: dto,
				url: `${Vgr.constants.suppliersHostUrl}
					${Vgr.resources.suppliers.supplierViewAudits.replace("[ORGANIZATION]", organizationId).replace("[UNIT]", client.id)}`
			};

			return $http(req);
		};

		const _deleteSupplierAudit = function (id) {
			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;

			const req = {
				method: "delete",
				url: `${Vgr.constants.suppliersHostUrl}
					${Vgr.resources.suppliers.supplierViewAudits.replace("[ORGANIZATION]", organizationId).replace("[UNIT]", client.id)}
					/${id}`
			};
			return $http(req);
		};

		const _updateSupplierAudit = function updateSupplierAudit(auditDTO) {
			const req = {
				method: "put",
				data: {
					Apply: auditDTO.toApply,
					SupplierNameToNotify: auditDTO.supplierName,
					SupplierEmailToNotify: auditDTO.supplierEmail
				},
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.audits + auditDTO.auditId
			};

			if (auditDTO.audit) {
				req.data = {
					AuditDate: auditDTO.audit.AuditDate,
					ExpirationDate: auditDTO.audit.ExpirationDate,
					Categories: auditDTO.audit.Categories,
					ResponsibleCode: auditDTO.audit.Responsible.code,
					SupervisorCode: auditDTO.audit.Supervisor ? auditDTO.audit.Supervisor.code : null,
					SupplierContactId: auditDTO.audit.SupplierContact ? auditDTO.audit.SupplierContact.Id : null,
					Attachments: auditDTO.Attachments,
					SupplierProfileIds: auditDTO.audit.ValidForAllProfiles ? [] : auditDTO.audit.Profiles,
					Level: auditDTO.audit.Level,
					OrganizationUnitId: auditDTO.OrganizationUnitId ?? auditDTO.audit?.Unit?.id,
					Active: auditDTO.audit.Active
				};
			}
			return $http(req);
		};

		const _applySupplierAudit = function applySupplierAudit(dto) {
			const auditDTO = {
				auditId: dto.auditId,
				toApply: true,
				audit: null,
				supplierEmail: dto.supplierEmail ? dto.supplierEmail : null,
				supplierName: dto.supplierName ? dto.supplierName : null
			};
			return _updateSupplierAudit(auditDTO);
		};

		const _listAuditModels = function listAuditModels(onlyActive = false) {
			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;
			let parameters = "";
			const data = {
				includeDefault: true,
				onlyActive: onlyActive
			};
			parameters = $.param(data);

			const req = {
				method: "get",
				url: Vgr.constants.suppliersHostUrl + `v1/organizations/${organizationId}/audit-models` + "?" + parameters
			};
			return $http(req);
		};

		const _listAuditItemClassifications = function () {
			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;
			const req = {
				method: "get",
				url: Vgr.constants.suppliersHostUrl + `v1/organizations/${organizationId}/audit-item-classifications`
			};
			return $http(req);
		};

		const _listAuditCategoryItemOptions = function () {
			const req = {
				method: "get",
				url: Vgr.constants.suppliersHostUrl + "v1/audit-category-item-options"
			};
			return $http(req);
		};

		const _getAuditLevelsDescriptions = function () {
			const deferred = $q.defer();

			preferencesService.getSupplierPreferences().then(
				function (supplierPreferences) {
					const auditLevelsDescriptions = {};

					auditLevelsDescriptions.level1 = supplierPreferences.auditLevelOneDescription
						? supplierPreferences.auditLevelOneDescription
						: $rootScope.labels.AUDIT_LEVEL1_TOOLTIP;
					auditLevelsDescriptions.level2 = supplierPreferences.auditLevelTwoDescription
						? supplierPreferences.auditLevelTwoDescription
						: $rootScope.labels.AUDIT_LEVEL2_TOOLTIP;
					auditLevelsDescriptions.level3 = supplierPreferences.auditLevelThreeDescription
						? supplierPreferences.auditLevelThreeDescription
						: $rootScope.labels.AUDIT_LEVEL3_TOOLTIP;

					$rootScope.auditLevelsDescriptions = auditLevelsDescriptions;
					deferred.resolve($rootScope.auditLevelsDescriptions);
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		};

		const _getDefaultAuditLevelDescription = function (level) {
			if (level == 1) {
				return $rootScope.labels.AUDIT_LEVEL1_TOOLTIP;
			} else if (level == 2) {
				return $rootScope.labels.AUDIT_LEVEL2_TOOLTIP;
			} else if (level == 3) {
				return $rootScope.labels.AUDIT_LEVEL3_TOOLTIP;
			}
			return null;
		};

		//UTIL
		function _loadClientClassification(audit) {
			let classification = audit.Classification;
			if (!classification) {
				classification = audit.classification;
			}
			switch (classification) {
				case Vgr.enumerations.audit.Classification.Approved:
				case Vgr.enumerations.audit.ClassificationText.Approved:
					audit.ClassificationDescription = $rootScope.labels.APPROVED;
					audit.ClassificationBorder = "audit-border-diamond";
					audit.ClassificationImg = "img/userApproved.png";
					break;
				case Vgr.enumerations.audit.Classification.ApprovedWithRestrictions:
				case Vgr.enumerations.audit.ClassificationText.ApprovedWithRestrictions:
					audit.ClassificationDescription = $rootScope.labels.APPROVED_WITH_RESTRICTIONS;
					audit.ClassificationBorder = "audit-border-gold";
					audit.ClassificationImg = "img/userApprovedWithRestrictions.png";
					break;
				case Vgr.enumerations.audit.Classification.Disapproved:
				case Vgr.enumerations.audit.ClassificationText.Disapproved:
					audit.ClassificationDescription = $rootScope.labels.DISAPPROVED;
					audit.ClassificationBorder = "audit-border-notRecommended";
					audit.ClassificationImg = "img/userRepproved.png";
					break;
			}
			return audit;
		}

		function _isOwner(audit, client) {
			return audit.Owner.CompanyId == client.organization.id;
		}

		function _hasAnyLevelPermission() {
			const permissions = [
				Vgr.systemPermissions.SupplierOrganizationMaster,
				Vgr.systemPermissions.SupplierOrganizationAuditLevel1,
				Vgr.systemPermissions.SupplierOrganizationAuditLevel2,
				Vgr.systemPermissions.SupplierOrganizationAuditLevel3,
				Vgr.systemPermissions.SupplierAuditLevel1,
				Vgr.systemPermissions.SupplierAuditLevel2,
				Vgr.systemPermissions.SupplierAuditLevel3
			];
			return permissions.some((p) => permissionService.hasPermissionV2(p));
		}

		function isApplied(audit) {
			return (
				audit.Status != Vgr.enumerations.audit.Status.Pending &&
				audit.Status != Vgr.enumerations.audit.Status.WaitingSupplier
			);
		}

		function _hasPermission(audit, supplier) {
			if (!audit || !supplier) {
				return false;
			}

			if (permissionService.hasPermissionV2(Vgr.systemPermissions.SupplierOrganizationMaster)) {
				return true;
			}

			const loggedUser = accountService.getLoggedAccount();
			const userResponsibleCode = getSupplierUserResponsibleCode(supplier);
			if (userResponsibleCode) {
				if (userResponsibleCode == loggedUser.code) {
					return true;
				} else if (!permissionService.hasPermissionV2(Vgr.systemPermissions.SupplierNotOwnedEdit)) {
					return false;
				}
			}

			if (isApplied(audit) || (audit.Responsible && loggedUser.code != audit.Responsible.Id)) {
				return false;
			}

			let organizationPermission;
			let unitPermission;
			if (audit.Level == Vgr.enumerations.audit.Level.Level1) {
				organizationPermission = Vgr.systemPermissions.SupplierOrganizationAuditLevel1;
				unitPermission = Vgr.systemPermissions.SupplierAuditLevel1;
			} else if (audit.Level == Vgr.enumerations.audit.Level.Level2) {
				organizationPermission = Vgr.systemPermissions.SupplierOrganizationAuditLevel2;
				unitPermission = Vgr.systemPermissions.SupplierAuditLevel2;
			} else if (audit.Level == Vgr.enumerations.audit.Level.Level3) {
				organizationPermission = Vgr.systemPermissions.SupplierOrganizationAuditLevel3;
				unitPermission = Vgr.systemPermissions.SupplierAuditLevel3;
			}

			return (
				permissionService.hasPermissionV2(organizationPermission) || permissionService.hasPermissionV2(unitPermission)
			);
		}

		function getSupplierUserResponsibleCode(supplier) {
			if (supplier) {
				if (supplier.UserResponsibleCode) {
					return supplier.UserResponsibleCode;
				}
				if (supplier.userResponsibleCode) {
					return supplier.userResponsibleCode;
				}
			}
			return null;
		}

		return {
			getSupplierAuditById: _getSupplierAuditById,

			listSupplierAudits: _listSupplierAudits,
			listAuditsAsAudited: _listAuditsAsAudited,

			createSupplierAudit: _createSupplierAudit,
			deleteSupplierAudit: _deleteSupplierAudit,
			updateSupplierAudit: _updateSupplierAudit,
			applySupplierAudit: _applySupplierAudit,
			listAuditModels: _listAuditModels,

			getAuditNotification: _getAuditNotification,
			transferAuditControl: _transferAuditControl,

			listAuditItemClassifications: _listAuditItemClassifications,
			listAuditCategoryItemOptions: _listAuditCategoryItemOptions,

			getAuditLevelsDescriptions: _getAuditLevelsDescriptions,
			getDefaultAuditLevelDescription: _getDefaultAuditLevelDescription,

			loadClientClassification: _loadClientClassification,
			isOwner: _isOwner,
			hasPermission: _hasPermission,
			hasAnyLevelPermission: _hasAnyLevelPermission
		};
	}
]);
